<template>
  <div class="fragment">
    <template v-if="$store.getters.getReturnGoods.length > 0 && $store.getters.getReturnGoodsLoading !== true">
      <div class="custom-row pt-2">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getReturnGoods" :key="index">
          <Item
              :img="item.image_files.length > 0 ? `data:${item.image_files[0].extension};base64,` + item.image_files[0].file : '/img/trash-group/unidentified-returns-img.jpg'"
              :images="item.image_files"
          >
            <template slot="head">
<!--              <router-link-->
<!--                  :to="$store.getters.GET_PATHS.problemsReturnGoodsUrl + '/show/' + item.id"-->
<!--                  class="case-cart__title btn-style">-->
<!--              </router-link>-->
              <div class="case-cart__title">
                #{{ item.id }}
              </div>

              <div class="case-cart__status">
                <ManagerButton class="alone more-180 bottom right">
                  <template
                      v-if="isAdmin"
                      slot="item">
                    <LinkButton
                        :value="$t('common_edit.localization_value.value')"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.problemsReturnGoodsUrl + '/' + item.id"
                    />
                  </template>
                  <template
                      v-if="isAdmin"
                      slot="item">
                    <LinkButton
                        :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="removeReturnedPackage(item.id, index)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </template>

            <template slot="content">
              <div class="case-cart__content-block">
                <div class="case-cart__row custom-row p-0">
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['returnGoods_shipped'])"></div>
                      {{ $t('returnGoods_shipped.localization_value.value') }}
                    </div>
                    <div class="case-cart__content">
                      {{ item.created_at | moment("DD MMM, YYYY") }}
                    </div>
                  </div>
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['returnGoods_trackingNumber'])"></div>
                      {{ $t('returnGoods_trackingNumber.localization_value.value') }}
                    </div>
                    <div class="case-cart__content word-break">
                      <TooltipTextHelper>
                        <template slot="text"><span class="text-ellipsis tracking-number">{{ item.tracking_number }}</span></template>
                        <template slot="title">{{ item.tracking_number }}</template>
                      </TooltipTextHelper>
                    </div>
                  </div>
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['returnGoods_search'])"></div>
                      {{$t('returnGoods_search.localization_value.value')}}
                    </div>
                    <div class="case-cart__content word-break">
                      <TooltipTextHelper v-if="item.modelInstance">
                        <template slot="text"><span class="text-ellipsis tracking-number"><ValueHelper :value="item.modelInstance.tracking_number"/></span></template>
                        <template slot="title"><ValueHelper :value="item.modelInstance.tracking_number"/></template>
                      </TooltipTextHelper>
                      <TooltipTextHelper v-else-if="item.starter_track">
                        <template slot="text"><span class="text-ellipsis tracking-number"><ValueHelper :value="item.starter_track"/></span></template>
                        <template slot="title"><ValueHelper :value="item.starter_track"/></template>
                      </TooltipTextHelper>
                      <template v-else>
                        —
                      </template>
                    </div>
                  </div>
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['returnGoods_storage'])"></div>
                      {{$t('returnGoods_storage.localization_value.value')}}
                    </div>
                    <div class="case-cart__content word-break">
                      <ValueHelper
                          :value="item"
                          :deep="'warehouse_storage.name'"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template slot="contentFooter">
              <div class="case-cart__content-block">
                <template
                    v-if="isAdmin">
                  <div class="case-cart__row custom-row p-0"
                       v-for="(itemRequest, index) in item.return_parcel_requests"
                       :key="index"
                  >
                    <TableUserNameRow
                        :item="itemRequest"
                        :colWidth="'custom-col--50'"
                        class="cursor-pointer"
                        @click.native="changeReturnPackagePopup(true, false, itemRequest.id)"
                    />
                    <div class="case-cart__col custom-col custom-col--50">
                      <div class="case-cart__content">
                        <MainButton
                            :value="$t('returnGoods_accept.localization_value.value')"
                            @click.native="attachUserQuestion(item.id, itemRequest.user_id)"
                        />
                      </div>
                    </div>
                  </div>
                </template>

                <div class="case-cart__row custom-row p-0">
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['returnGoods_comment'])"></div>
                      {{ $t('returnGoods_comment.localization_value.value') }}
                    </div>
                    <div class="case-cart__content word-break">
                      <TooltipTextHelper :widthPopover="240" :marginLeftPopover="1">
                        <template slot="text"><span class="text-ellipsis">{{ item.comment }}</span></template>
                        <template slot="title">{{ item.comment }}</template>
                      </TooltipTextHelper>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template slot="footer">
              <div class="case-cart__btn ml-auto"
                   v-if="isAdmin"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['returnGoods_addUser'])"></div>
                <CaseCartButton
                    :value="$t('returnGoods_addUser.localization_value.value')"
                    @click.native="changeAddUserReturnGoodsPopup(true, item.id)"
                >
                </CaseCartButton>
              </div>
              <div class="case-cart__btn ml-auto"
                   v-else
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['returnGoods_SendRequest', 'returnGoods_inProcess'])"></div>
                <CaseCartButton
                    :value="canSendRequest(item) ? $t('returnGoods_SendRequest.localization_value.value') : $t('returnGoods_inProcess.localization_value.value')"
                    :disabled="!canSendRequest(item)"
                    @click.native="changeReturnPackagePopup(true, item.id)"
                >
                </CaseCartButton>
              </div>
            </template>
          </Item>
        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getReturnGoodsCommonList.next_page_url !== null && $store.getters.getReturnGoods.length > 0"
              @click.native="$emit('showMore')"
              v-bind:class="{'disabled-btn' : $store.getters.getNextReturnGoodsPage}"
              :count="$store.getters.getReturnGoodsCommonList.total - $store.getters.getReturnGoodsForPage < $store.getters.getReturnGoodsForPage ?
                  $store.getters.getReturnGoodsCommonList.total - $store.getters.getReturnGoodsForPage:
                  $store.getters.getReturnGoodsForPage"
          />
        </div>
      </div>

    </template>

    <template v-if="$store.getters.getReturnGoodsLoading === false && $store.getters.getReturnGoods.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

    <RequestReturnPackagePopup
        v-if="isModalReturnPackagePopup"
        :undefinedReturnPackageId="undefinedReturnPackageId"
        :requestReturnPackageId="requestReturnPackageId"
        @close="changeReturnPackagePopup(false)"
        @reload="$emit('reload')"
    />

    <AddUserReturnGoodsPopup
        v-if="isModalAddUserReturnGoodsPopup"
        :returnGoodsId="undefinedReturnPackageId"
        @close="changeAddUserReturnGoodsPopup(false)"
        @reload="$emit('reload')"
    />

  </div>
</template>

<script>
import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
import RequestReturnPackagePopup from "../../../../popups/RequestReturnPackagePopup/RequestReturnPackagePopup";
import ShowMore from "@/components/coreComponents/Buttons/ShowMore/ShowMore";
import NoResult from "@/components/coreComponents/NoResult/NoResult";
import ManagerButton from "@/components/UI/buttons/ManagerButton/ManagerButton";
import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
import {returnGoodsMixin} from "@/mixins/returnGoodsMixins/returnGoodsMixin";
import TableUserNameRow from "@/components/coreComponents/TableComponents/TableUserNameRow/TableUserNameRow";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import AddUserReturnGoodsPopup
  from "@/components/modules/ReturnGoodsModule/popups/AddUserReturnGoodsPopup/AddUserReturnGoodsPopup";
import Item from "@/components/modules/ReturnGoodsModule/components/chunks/Item/Item";
import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";

export default {
  name: "UnidentifiedReturnsList",
  components: {
    ValueHelper,
    TooltipTextHelper,
    AddUserReturnGoodsPopup,
    MainButton,
    TableUserNameRow,
    LinkButton,
    ManagerButton,
    NoResult,
    ShowMore,
    Item,
    CaseCartButton,
    RequestReturnPackagePopup,
  },

  mixins: [returnGoodsMixin],

  props: {
    countFilterParams: Number,
  },

  data() {
    return {
      isModalReturnPackagePopup: false,
      isModalAddUserReturnGoodsPopup: false,
      undefinedReturnPackageId: null,
      requestReturnPackageId: null,
    }
  },

  methods: {

    canSendRequest(item) {
      let foundUserSendRequest = this._.find(item.return_parcel_requests, {user_id: this.getCurrentUser?.id})
      if(foundUserSendRequest){
        return false
      }
      return true
    },

    changeReturnPackagePopup(val, undefinedId = false, requestId = false) {
      this.isModalReturnPackagePopup = val
      if(undefinedId){
        this.requestReturnPackageId = null
        this.undefinedReturnPackageId = undefinedId
      }
      if(requestId){
        this.undefinedReturnPackageId = null
        this.requestReturnPackageId = requestId
      }
    },

    changeAddUserReturnGoodsPopup(val, undefinedId = false) {
      this.isModalAddUserReturnGoodsPopup = val
      if(undefinedId){
        this.undefinedReturnPackageId = undefinedId
      } else {
        this.undefinedReturnPackageId = null
      }
    },

    attachUserQuestion(returnId, userId) {
      let text = {
        title: 'returnGoods_attachQuestion',
        txt: '',
        yes: 'common_yes',
        no: 'common_no'
      }

      let confirm = () => {

        this.attachUser(returnId, userId, false)

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },


  }
}
</script>

<style scoped>

.text-ellipsis{
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ellipsis.tracking-number{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>